import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Kpis } from 'services/kpis';
import { Products } from 'services/products';
import { I18n } from 'common/i18n';
import Chart from 'chart.js/auto';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';
import { WIDGET, CHART_COLORS, LOB } from 'common/constants';
import { mode } from 'common/ui';
import { c } from 'common/common';

export class Kpi extends TimePeriodNavigatorBase {
    static inject = [Router, EventAggregator, Kpis, Products, I18n];
    _router;
    _kpis;
    _products;
    _i18n;

    @bindable kpi;
    @bindable sync;

    chartEl;
    showChart = true;

    _handlers = [];

    constructor(router, ea, kpis, products, i18n) {
        super('kpi', ea);
        this._router = router;
        this._kpis = kpis;
        this._products = products;
        this._i18n = i18n;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
        this._loadProductTypes();
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.modeChanged, () => {
            if (this._chart) {
                this._chart.destroy();
                this._chart = undefined;
            }
            this.refreshData();
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _loadProductTypes() {
        try {
            const productTypes = await this._products.typesByLOB();
            this.totalLifeProductTypes = productTypes.find(x => x.lineOfBusiness === LOB.Life).productTypes.length;
        } catch (err) {
            console.log(err);
        }
    }

    kpiChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async refreshData() {
        this.refreshing = true;
        await this._loadData();
        this.refreshing = false;
    }

    async _loadData(fromSync = false) {
        if (!this.kpi || !this.chartEl) return;
        try {
            this.loading = true;
            this.initializeTimePeriodNavigation(fromSync);

            this.kpiProgress = await this._kpis.progress2(this.kpi, this.asOfDate.toISOString(true));
            this.showChart = true;

            this.timePeriod = WIDGET.Period.toReportTimePeriod(this.kpi.period);
            this.currentPeriod = this.kpiProgress.timePeriod;
            this.setTimePeriodNavigationDisplays();
            this._loadChart();
        } catch (err) {
            console.log(err);
            this.showChart = false;
        } finally {
            this.loading = false;
        }
    }

    _loadChart() {
        try {
            const uiMode = mode();

            const data = {
              labels: [this._i18n.tr('kpi-progress'), this._i18n.tr('kpi-short')],
              datasets: [
                    {
                        label: '',
                        data: [this.kpiProgress.progress, this.kpiProgress.neededToMeetGoal],
                        backgroundColor: CHART_COLORS.KpiCurrent(uiMode.mode),
                        borderJoinStyle: 'miter',
                        borderRadius: 15,
                        borderWidth: 0,
                        borderColor: 'transparent',
                        spacing: 2,
                        settings: { timePeriod: this.kpiProgress.timePeriod },
                    }
                ]
            };
            const timePeriodProgress = this.getTimePeriodProgress();
            if (timePeriodProgress) {
                data.datasets.push({
                    label: '',
                    data: [timePeriodProgress.percentComplete, timePeriodProgress.percentRemaining],
                    backgroundColor: CHART_COLORS.KpiProgress(uiMode.mode),
                    borderJoinStyle: 'miter',
                    borderRadius: 3,
                    borderWidth: 0,
                    borderColor: 'transparent',
                    weight: .4,
                    spacing: 2,
                    settings: { timePeriod: this.kpiProgress.timePeriod, percentComplete: timePeriodProgress.percentComplete },
                });
            }
            if (this.kpiProgress.priorKpi1) {
                data.datasets.push({
                    label: '',
                    data: [this.kpiProgress.priorKpi1.progress, this.kpiProgress.priorKpi1.neededToMeetGoal],
                    backgroundColor: CHART_COLORS.KpiPrior1(uiMode.mode),
                    borderJoinStyle: 'miter',
                    borderRadius: 15,
                    borderWidth: 0,
                    borderColor: 'transparent',
                    spacing: 2,
                    settings: { timePeriod: this.kpiProgress.priorKpi1.timePeriod },
                });
            }
            if (this.kpiProgress.priorKpi2) {
                data.datasets.push({
                    label: '',
                    data: [this.kpiProgress.priorKpi2.progress, this.kpiProgress.priorKpi2.neededToMeetGoal],
                    backgroundColor: CHART_COLORS.KpiPrior2(uiMode.mode),
                    borderJoinStyle: 'miter',
                    borderRadius: 15,
                    borderWidth: 0,
                    borderColor: 'transparent',
                    spacing: 2,
                    settings: { timePeriod: this.kpiProgress.priorKpi2.timePeriod },
                });
            }
            const plugin = {
                id: 'content-at-center',
                beforeDraw: (chart) => {
                    let width = chart.width;
                    let height = chart.height;
                    let ctx = chart.ctx;
              
                    ctx.restore();

                    const centerText = `${this.kpiProgress.isCurrency ? '$' : ''}${this.kpiProgress.progress.formatMoney(2)}`;
                    let heightDivisor = 50;
                    if (centerText.length >= 12) {
                        heightDivisor = 175;
                    } else if (centerText.length >= 9) {
                        heightDivisor = 150;
                    } else if (centerText.length >= 7) {
                        heightDivisor = 125;
                    } else if (centerText.length >= 6) {
                        heightDivisor = 115;
                    } else if (centerText.length >= 5) {
                        heightDivisor = 100;
                    } else if (centerText.length >= 3) {
                        heightDivisor = 75;
                    }

                    let fontSize = (height / heightDivisor).toFixed(2);
                    ctx.font = `${fontSize}em sans-serif`;
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = uiMode.fontColor;
              
                    let textX = Math.round((width - ctx.measureText(centerText).width) / 2);
                    let textY = height / 1.9;
              
                    ctx.fillText(centerText, textX, textY);
                    ctx.save();
                }
            };
            const config = {
                type: 'doughnut',
                data: data,
                plugins: [plugin],
                options: {
                    responsive: true,
                    cutout: '60%',
                    plugins: {
                        legend: { display: false },
                        title: { display: false },
                        tooltip: {
                            callbacks: {
                                label: (ctx) => {
                                    if (!ctx || !ctx.dataset || !ctx.dataset.settings) return '';
                                    const timePeriodDisplay = this.getTimePeriodDisplay(ctx.dataset.settings.timePeriod);
                                    if (ctx.dataset.settings.percentComplete) {
                                        return this._i18n.tr('time-period-percent-complete', { period: timePeriodDisplay, percent: ctx.dataset.settings.percentComplete });
                                    }
                                    const formattedValue = `${this.kpiProgress.isCurrency ? '$' : ''}${ctx.formattedValue}`;
                                    return [timePeriodDisplay, `${ctx.label}: ${formattedValue}`];
                                },
                            },
                        },
                    },
                },
            };

            if (this._chart) {
                this._chart.data.datasets = data.datasets;
                this._chart.update();
                return;
            }

            this._chart = new Chart(this.chartEl, config);
        } catch (err) {
            console.log(err);
        }
    }
}
