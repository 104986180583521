import { bindable } from 'aurelia-framework';
import { Content } from 'common/content';
import { c } from 'common/common';
import tinymce from 'tinymce';

export class SimpleHtmlEditorInline {
    static inject = [Element, Content];
    _element;
    _content;

    @bindable html = '';
    @bindable variables;
    @bindable resetHtml = undefined;

    _activeEditor;

    editorVariables = [];
    variableFilters = [{ value: '', keys: ['v'] }];

    constructor(element, content) {
        this._element = element;
        this._content = content;
        this.id = c.Helpers.uniqueId();
    }

    attached() {
        this._initializeTinyMce();
        if (this._activeEditor && this._activeEditor.initialized) {
            this._setContent();
        }
    }

    detached() {
        if (this._activeEditor) this._activeEditor.remove();
        this._activeEditor = undefined;
    }

    resetHtmlChanged() {
        if (this.resetHtml === undefined) return;
        if (!this._activeEditor) return;
        this.html = this.resetHtml;
        this.resetHtml = undefined;
        this._setContent()
    }

    variablesChanged() {
        if (!this.variables) return;
        this.variables.forEach(v => this.editorVariables.push({ v }));
    }

    focusSearch() {
        this.focusFilter = true;
        return false;
    }

    insertText(t) {
        this._activeEditor.insertContent(t);
        this.variableFilters[0].value = '';
    }

    _setContent() {
        if (this._activeEditor && this._activeEditor.initialized) {
            this._activeEditor.setContent(this.html || '');
        }
    }

    _initializeTinyMce() {
        tinymce.baseURL = '/tinymce';
        let toolbarRow1 = 'undo redo | styleselect | forecolor backcolor | bold italic';
        let toolbarRow2 = 'alignleft aligncenter alignright | bullist numlist outdent indent | code';
        tinymce.init({
            selector: `textarea#${this.id}`,
            theme: 'modern',
            height: 250,
            
            remove_script_host: false,
            convert_urls: false,

            plugins: ['colorpicker advlist autolink lists textcolor code'],

            toolbar1: toolbarRow1,
            toolbar2: toolbarRow2,

            menubar: false,
            toolbar_items_size: 'small',
            extended_valid_elements: '',
            valid_elements: '*[*]',

            setup: (ed) => {
                this._activeEditor = ed;
                ed.on('init', () => {
                    ed.getBody().setAttribute('spellcheck', true);
                    this._setContent();
                });
                ed.on('change keyup', (e) => {
                    this._element.dispatchEvent(new CustomEvent('changed', { bubbles: true, detail: { html: this._activeEditor.getContent() } }));
                });
            },
        });
    }
}
