import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Kpis, KPI_PERIOD } from 'services/kpis';
import { I18n } from 'common/i18n';
import Chart from 'chart.js/auto';
import { TimePeriodNavigatorBase } from 'resources/base-classes/time-period-navigator-base';

export class KpiSimple extends TimePeriodNavigatorBase {
    static inject = [Router, EventAggregator, Kpis, I18n];
    _router;
    _kpis;
    _i18n;

    @bindable kpi;
    @bindable sync;

    chartEl;
    showChart = true;

    constructor(router, ea, kpis, i18n) {
        super('kpi-simple', ea);
        this._router = router;
        this._kpis = kpis;
        this._i18n = i18n;
    }

    attached() {
        this._isAttached = true;
        this._attached();
        this._loadData();
    }

    kpiChanged() {
        if (!this._isAttached) return;
        this._loadData();
    }

    async refreshData() {
        this.refreshing = true;
        await this._loadData();
        this.refreshing = false;
    }

    async _loadData(fromSync = false) {
        if (!this.kpi || !this.chartEl) return;
        try {
            this.loading = true;
            this.initializeTimePeriodNavigation(fromSync);

            this.kpiProgress = await this._kpis.progress(this.kpi, this.asOfDate.toISOString(true));
            this.showChart = true;

            this.timePeriod = KPI_PERIOD.toTimePeriod(this.kpiProgress.kpiPeriod);
            this.currentPeriod = this.kpiProgress.timePeriod;
            this.setTimePeriodNavigationDisplays();
            this._loadChart();
        } catch (err) {
            console.log(err);
            this.showChart = false;
        } finally {
            this.loading = false;
        }
    }

    _loadChart() {
        try {
            const dayOfYear = moment().dayOfYear();
            const remainingDays = 366 - dayOfYear;
            const data = {
              labels: [this._i18n.tr('kpi-progress'), this._i18n.tr('kpi-short')],
              datasets: [
                    {
                        label: '',
                        data: [this.kpiProgress.progress, this.kpiProgress.neededToMeetGoal],
                        backgroundColor: ['#B8D935', '#F4FBDB'],
                        borderJoinStyle: 'miter',
                        borderRadius: 15,
                    },
                    {
                        label: '',
                        data: [dayOfYear, remainingDays],
                        backgroundColor: ['lightgray', 'transparent'],
                        borderJoinStyle: 'miter',
                        borderRadius: 3,
                        weight: .4,
                    }
                ]
            };
            const plugin = {
                id: 'content-at-center',
                beforeDraw: (chart) => {
                    let width = chart.width;
                    let height = chart.height;
                    let ctx = chart.ctx;
              
                    ctx.restore();

                    const centerText = `${this.kpiProgress.isCurrency ? '$' : ''}${this.kpiProgress.progress.formatMoney(0)}`;
                    let heightDivisor = 50;
                    if (centerText.length >= 9) {
                        heightDivisor = 150;
                    } else if (centerText.length >= 7) {
                        heightDivisor = 125;
                    } else if (centerText.length >= 6) {
                        heightDivisor = 115;
                    } else if (centerText.length >= 5) {
                        heightDivisor = 100;
                    } else if (centerText.length >= 3) {
                        heightDivisor = 75;
                    }

                    let fontSize = (height / heightDivisor).toFixed(2);
                    ctx.font = `${fontSize}em sans-serif`;
                    ctx.textBaseline = 'middle';
              
                    let textX = Math.round((width - ctx.measureText(centerText).width) / 2);
                    let textY = height / 1.9;
              
                    ctx.fillText(centerText, textX, textY);
                    ctx.save();
                }
            }
            const config = {
                type: 'doughnut',
                data: data,
                plugins: [plugin],
                options: {
                    responsive: true,
                    cutout: '85%',
                    plugins: {
                        legend: { display: false },
                        title: { display: false }
                    }
                },
            };

            if (this._chart) {
                this._chart.data.datasets = data.datasets;
                this._chart.update();
                return;
            }

            this._chart = new Chart(this.chartEl, config);
        } catch (err) {
            console.log(err);
        }
    }
}
