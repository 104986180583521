import { observable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Carriers } from 'services/carriers';
import { Members } from 'services/members';
import { c } from 'common/common';

export class ContractedInState {
    static inject = [Security, Carriers, Members];
    _security;
    _carriers;
    _members;

    @observable({ changeHandler: '_load' }) usState;
    @observable({ changeHandler: '_load' }) carrierId;

    downline;
    run = false;

    constructor(security, carriers, members) {
        this._security = security;
        this._carriers = carriers;
        this._members = members;
		this.usStates = c.Geography.usStates;
    }

    attached() {
        this._initialize();
    }

    async _initialize() {
        try {
            this.carriers = await this._carriers.all();
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        if (!this.usState && !this.carrierId) return;
        this.loading = true;
        try {
            this.downline = await this._members.downlineContractedInState(this._security.authenticatedMemberId, this.usState, this.carrierId);
            this.run = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
