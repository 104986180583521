import { bindable, observable } from 'aurelia-framework';
import { Kpis } from 'services/kpis';
import moment from 'moment';

export class KpiHealth {
    static inject = [Kpis];
    _kpis;

    @bindable({ changeHandler: '_load' }) memberId;
    @bindable reload;
    @bindable productionType = 'submitted';

    momentTimePeriod = 'week';
    asOfDate;
    reload;
    @observable kpiAsOfDate = moment();

    @observable downlineMember;

    constructor(kpis) {
        this._kpis = kpis;
    }

    reloadChanged() {
        if (!this.reload) return;
        this._load();
    }

    previous() {
        if (!this.canGoPrevious) return;
        this.asOfDate = this.asOfDate.add(-1, this.momentTimePeriod);
        this._load();
    }

    next() {
        if (!this.canGoNext) return;
        this.asOfDate = this.asOfDate.add(1, this.momentTimePeriod);
        this._load();
    }

    kpiAsOfDateChanged() {
        this.asOfDate = this.kpiAsOfDate.startOf('day').utc();
        this._load();
    }

    current() {
        this.asOfDate = undefined;
        this._load();
    }

    downlineMemberChanged() {
        this._load();
    }

    selectProductionType(productionType) {
        this.productionType = productionType;
        this._load();
    }

    async _load() {
        if (!this.memberId) return;
        this.loading = true;
        try {
            let initialLoad = false;
            if (!this.asOfDate) {
                initialLoad = true;
                this.asOfDate = moment().utc();
            }
            const memberId = this.downlineMember ? this.downlineMember.id : this.memberId;
            this.health = await this._kpis.health(memberId, this.asOfDate.toISOString(true), this.productionType);
            if (this.health === null) {
                // This member doesn't have data to display for the health widget
                return;
            }
            if (initialLoad) this.asOfDate = moment.utc(this.health.weekPeriod.end); // on initial, adjust to the end of the week
            const nextTimePeriodStartDate = moment.utc(this.health.weekPeriod.end).add(1, 'day');
            this.canGoNext = !nextTimePeriodStartDate.isAfter(moment().utc().endOf('day'));
            this.canGoPrevious = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    calculateValueSize(value) {
        if (value < 10) return 1;
        if (value < 100) return 2;
        if (value < 1000) return 3;
        if (value < 10000) return 4;
        if (value < 100000) return 5;
        if (value < 1000000) return 6;
        return '';
    }

    displayKpi(kpi) {
        this.viewKpi = kpi;
        const weekLineDatasets = [];
        weekLineDatasets.push({
            key: 'kpi-13-week-rolling-average',
            data: kpi.week.sparkline,
            color: 'green',
        });
        weekLineDatasets.push({
            key: kpi.kpiType,
            data: kpi.week.sparklineKpi,
            color: 'blue',
        });
        this.weekLineDatasets = weekLineDatasets;
        const monthLineDatasets = [];
        monthLineDatasets.push({
            key: 'kpi-5-month-rolling-average',
            data: kpi.month.sparkline,
            color: 'green',
        });
        monthLineDatasets.push({
            key: kpi.kpiType,
            data: kpi.month.sparklineKpi,
            color: 'blue',
        });
        this.monthLineDatasets = monthLineDatasets;
        this.showDetails();
    }

    async refreshData() {
        this.refreshing = true;
        await this._load();
        this.refreshing = false;
    }

    async showDetails() {
        this.showDrawer = moment().format();
    }

    drawerClosed() {
        this.viewKpi = undefined;
    }
}
