import { bindable, computedFrom } from 'aurelia-framework';

export class KpiNumber {
    @bindable loading;
    @bindable title;
    @bindable value;
    @bindable decimals = 0;
    @bindable increasePercent;
    @bindable increaseCount;
    @bindable progressPeriod;
    @bindable isPercent = false;
    @bindable isMoney = false;
    @bindable size;

    @computedFrom('increasePercent')
    get increasePercentCss() {
        if (this.increasePercent > 0) return 'success';
        if (this.increasePercent < 0) return 'error';
        return '';
    }
}