import { observable } from 'aurelia-framework';
import { Carriers } from 'services/carriers';

export class ContractingHierarchy {
    static inject = [Carriers];
    _carriers;

    @observable({ changeHandler: '_load' }) member;
    @observable({ changeHandler: '_load' }) carrierId;

    carriers;

    constructor(carriers) {
        this._carriers = carriers;
    }

    attached() {
        this._initialize();
    }

    async _initialize() {
        try {
            this.carriers = await this._carriers.all();
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        if (!this.member || !this.carrierId) return;
        this.loading = true;
        try {
            this.hierarchy = await this._carriers.contractingHierarchy(this.carrierId, this.member.id);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
