import { DialogController } from 'aurelia-dialog';
import { Content } from 'common/content';
import { c } from 'common/common';
import tinymce from 'tinymce';
import environment from '../../../../config/environment.json';

export class HtmlEditorDialog {
    static inject = [DialogController, Content]
    dialogController;
    _content;

    variableFilters = [{ value: '', keys: ['v'] }];

    constructor(dialogController, content) {
        this.dialogController = dialogController;
        this._content = content;

        this.dialogController.settings.centerHorizontalOnly = true;
        this.id = c.Helpers.uniqueId();
    }

    async activate(model) {
        this.html = model.html;
        this.title = model.title;
        this.instructionsKey = model.instructionsKey;
        this.callback = model.callback;
        this.variables = [];
        model.variables.forEach(v => this.variables.push({ v }));
    }

    attached() {
        this._initializeTinyMce();
        let activeEditor = tinymce.activeEditor;
        if (activeEditor.initialized) {
            this._setContent(activeEditor);
        } else {
            let me = this;
            activeEditor.on('init', function() {
                me._setContent(this);
            });
        }
    }

    detached() {
        let ae = tinymce.activeEditor;
        if (ae) ae.remove();
    }

    focusSearch() {
        this.focusFilter = true;
        return false;
    }

    insertText(t) {
        tinymce.activeEditor.insertContent(t);
        this.variableFilters[0].value = '';
    }

    _setContent(activeEditor) {
        if (activeEditor.initialized) activeEditor.setContent(this.html);
    }

    _initializeTinyMce() {
        let me = this;
        tinymce.baseURL = '/tinymce';
        let toolbarRow1 = 'undo redo | styleselect | forecolor backcolor | bold italic | link | image';
        let toolbarRow2 = 'table | bootstrap | hr | alignleft aligncenter alignright | bullist numlist outdent indent | code';
        tinymce.init({
            selector: `textarea#${this.id}`,
            theme: 'modern',
            height: 500,
            
            remove_script_host: false,
            convert_urls: false,

            plugins: ['table colorpicker advlist autolink lists link hr anchor textcolor code image'],
            external_plugins: {
                'bootstrap': '/js-lib/tinymce-plugins/bootstrap/plugin.min.js',
            },

            toolbar1: toolbarRow1,
            toolbar2: toolbarRow2,
            //toolbar2: 'mc-merge-email mc-merge-fname mc-merge-lname',
            //setup: function(editor) {
            //    editor.addButton('mc-merge-email', { text: 'Insert Email', icon: false, onclick: function() { editor.insertContent('*|EMAIL|*') } }),
            //    editor.addButton('mc-merge-fname', { text: 'Insert First Name', icon: false, onclick: function() { editor.insertContent('*|FNAME|*') } }),
            //    editor.addButton('mc-merge-lname', { text: 'Insert Last Name', icon: false, onclick: function() { editor.insertContent('*|LNAME|*') } })
            //},

            menubar: false,
            toolbar_items_size: 'small',
            extended_valid_elements: '',
            valid_elements: '*[*]',

            file_browser_callback: function (field_name, url, type, win) {
                me._openFileManager(`#${field_name}`, win);
            },

            setup: function (ed) {
                ed.on('init', function(evt) {
                    ed.getBody().setAttribute('spellcheck', true);
                });
            },

            bootstrapConfig: {
                'bootstrapElements': {
                    'template': true
                }
            }
        });
    }

    _openFileManager(elCssSelector, win, callback, fileType) {
        this._content.fileManager(file => {
            const name = file.displayName || file.fileName;
            const inputEl = win.document.querySelectorAll(elCssSelector)[0];
            inputEl.value = `${environment.api}/${file.path}`;

            if (callback) callback(file.path);
        }, fileType);
    }

    async save(closeEditor) {
        try {
            const html = tinymce.activeEditor.getContent();
            await this.callback.fn(this.callback.id, html);
            if (closeEditor) this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }
}
