import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Google } from 'services/google';
import { c } from 'common/common';

export class GoogleMap {
    static inject = [EventAggregator, Google];
    _ea;
    _google;

    mapContainerEl;

    @bindable({ changeHandler: '_embedMap' }) placeId;
    @bindable({ changeHandler: '_embedMap' }) address;
    @bindable({ changeHandler: '_embedMap' }) latLon;
    @bindable({ changeHandler: '_embedMap' }) view = 'place';
    @bindable enlarge;
    @bindable zoom = 15;

    googleMapSrc;
    embedSrc = 'about:blank';

    constructor(ea, google) {
        this._ea = ea;
        this._google = google;
    }

    attached() {
        this._isAttached = true;
        this._embedMap();
    }

    zoomChanged() {
        if (!this.zoom) return;
        this._embedMap();
    }

    _embedMap() {
        if (!this._isAttached) return;
        if (!this.mapContainerEl) return;
        if (!this._google.initialized) return;
        if (!this.placeId && !this.address && !this.latLon) return;
        if (!this.view) this.view = 'place';

        this.googleMapSrc = `https://www.google.com/maps/embed/v1/${this.view}?key=${this._google.mapsApiKey}`;
        let hasLocationData = true;
        if (this.view === 'place') {
            this.googleMapSrc += `&zoom=${this.zoom || 15}`;
            if (this.placeId) this.googleMapSrc += `&q=place_id:${encodeURIComponent(this.placeId)}`;
            else if (this.address) this.googleMapSrc += `&q=${encodeURIComponent(this.address)}`;
            else this.hasLocationData = false;
        } else if (this.view === 'streetview') {
            if (this.latLon) this.googleMapSrc += `&location=${this.latLon}`;
            else this.hasLocationData = false;
        }
        if (!hasLocationData) return;
        this.mapContainerEl.innerHTML = `<iframe src="${this.googleMapSrc}" style="border: 0; width: 100%;" frameborder="0" scrolling="no" referrerpolicy="no-referrer-when-downgrade" allowfullscreen></iframe>`;
    }

    enlargeChanged() {
        if (!this.enlarge) return;
        this.showInLightbox();
    }

    showInLightbox() {
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: this.googleMapSrc, fullHeight: true, zIndex: 2010 });
    }
}
