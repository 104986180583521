import { bindable, computedFrom } from 'aurelia-framework';

export class HeroNumber {
    static inject = [Element];
    @bindable preNumber = null;
    @bindable preNumberDecimals = 0;
    @bindable number;
    @bindable decimals = 0;
    @bindable label;
    @bindable showDirection = false;
    @bindable direction;
    @bindable reverseDirectionColor = false;
    @bindable allowClick = false;
    @bindable size = 'md';
    @bindable isMoney = false;
    @bindable sparkline;

    constructor(element) {
        this.element = element;
    }

    @computedFrom('direction', 'reverseDirectionColor')
    get valueCssClass() {
        if (!this.reverseDirectionColor) {
            if (this.direction === 'up') return 'lpfn-success';
            if (this.direction === 'down') return 'lpfn-error';
            return '';
        }
        if (this.direction === 'up') return 'lpfn-error';
        if (this.direction === 'down') return 'lpfn-success';
        return '';
}
}